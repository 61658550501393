import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3623970a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-title" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "summary" }
const _hoisted_5 = { class: "project-section-title" }
const _hoisted_6 = { class: "ml-6" }
const _hoisted_7 = { class: "summary-title" }
const _hoisted_8 = { class: "summary-title" }
const _hoisted_9 = { class: "summary-title" }
const _hoisted_10 = { class: "summary-subtitle" }
const _hoisted_11 = { class: "summary-subtitle" }
const _hoisted_12 = { class: "summary-title" }
const _hoisted_13 = { class: "summary-title" }
const _hoisted_14 = { class: "summary-subtitle" }
const _hoisted_15 = { class: "summary-subtitle" }
const _hoisted_16 = { class: "summary-title" }
const _hoisted_17 = { class: "summary-title" }
const _hoisted_18 = { class: "summary-title" }
const _hoisted_19 = { class: "summary-title" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = ["src"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["src"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["src"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["src"]
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = ["src"]
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["src"]
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = ["src"]
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = ["src"]
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = ["innerHTML"]
const _hoisted_47 = ["src"]
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = ["innerHTML"]
const _hoisted_50 = ["src"]
const _hoisted_51 = ["innerHTML"]
const _hoisted_52 = ["src"]
const _hoisted_53 = ["innerHTML"]
const _hoisted_54 = ["innerHTML"]
const _hoisted_55 = ["innerHTML"]
const _hoisted_56 = ["innerHTML"]
const _hoisted_57 = ["src"]
const _hoisted_58 = ["innerHTML"]
const _hoisted_59 = ["innerHTML"]
const _hoisted_60 = ["innerHTML"]
const _hoisted_61 = ["src"]
const _hoisted_62 = ["innerHTML"]
const _hoisted_63 = ["src"]
const _hoisted_64 = ["innerHTML"]
const _hoisted_65 = ["innerHTML"]
const _hoisted_66 = ["innerHTML"]
const _hoisted_67 = ["innerHTML"]
const _hoisted_68 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageExtend = _resolveComponent("ImageExtend")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.showDialog) = $event)),
    fullscreen: "",
    "hide-overlay": "",
    transition: "dialog-bottom-transition"
  }, {
    default: _withCtx(() => [
      (_ctx.imageToExtend)
        ? (_openBlock(), _createBlock(_component_ImageExtend, {
            key: 0,
            imgId: _ctx.imageToExtend,
            imgur: false,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageToExtend = null))
          }, null, 8, ["imgId"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_card, { class: "project-container ranking-app-project-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_CloseButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              alt: "Ranking App logo",
              src: require('@/assets/images/projects/rankingApp/logo.png')
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("img", {
            alt: "Ranking App - Overview",
            src: require('@/assets/images/projects/rankingApp/demo.gif'),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/demo.gif')))
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.summary')), 1),
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("a", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollToPart('inShort')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.inShortTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("a", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scrollToPart('why')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.whyTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("a", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.scrollToPart('authImage')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authImageTitle')), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("a", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.scrollToPart('auth')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authTitle')), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("a", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.scrollToPart('image')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.imageTitle')), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("a", {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.scrollToPart('uiUx')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UiUxTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_13, [
                _createElementVNode("a", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.scrollToPart('tests')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.testsTitle')), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("a", {
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.scrollToPart('unitTests')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.unitTestsTitle')), 1)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("a", {
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.scrollToPart('e2eTests')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.e2eTitle')), 1)
                ])
              ]),
              _createElementVNode("li", _hoisted_16, [
                _createElementVNode("a", {
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.scrollToPart('githubActions')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.gaTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_17, [
                _createElementVNode("a", {
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.scrollToPart('security')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_18, [
                _createElementVNode("a", {
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.scrollToPart('ssl')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.sslTitle')), 1)
              ]),
              _createElementVNode("li", _hoisted_19, [
                _createElementVNode("a", {
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.scrollToPart('conclusion')))
                }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionTitle')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", {
            ref: "inShort",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.inShortTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.inShortList')
          }, null, 8, _hoisted_20),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.inShortContent')
          }, null, 8, _hoisted_21),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.inShortCode')
          }, null, 8, _hoisted_22),
          _createElementVNode("div", {
            ref: "why",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.whyTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.whyContent')
          }, null, 8, _hoisted_23),
          _createElementVNode("div", {
            ref: "authImage",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authImageTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authImageContent')
          }, null, 8, _hoisted_24),
          _createElementVNode("div", {
            ref: "auth",
            class: "project-section-content-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.authContent')
          }, null, 8, _hoisted_25),
          _createElementVNode("div", {
            ref: "image",
            class: "project-section-content-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.imageTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.imageContent')
          }, null, 8, _hoisted_26),
          _createElementVNode("div", {
            ref: "uiUx",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UiUxTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content-title pt-2",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UiContent')
          }, null, 8, _hoisted_27),
          _createElementVNode("img", {
            alt: "Ranking App - Responsive",
            style: {"height":"375px","width":"fit-content"},
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/responsive.gif'),
            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/responsive.gif')))
          }, null, 8, _hoisted_28),
          _createElementVNode("div", {
            class: "project-section-content-title pt-2 mb-2",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxContent')
          }, null, 8, _hoisted_29),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList')
          }, null, 8, _hoisted_30),
          _createElementVNode("img", {
            alt: "Ranking App - Popup de confirmation (template)",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/confirmationTemplate.webp'),
            onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/confirmationTemplate.webp')))
          }, null, 8, _hoisted_31),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList2')
          }, null, 8, _hoisted_32),
          _createElementVNode("img", {
            alt: "Ranking App - Popup de confirmation (tierlist)",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/confirmationTierlist.webp'),
            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/confirmationTierlist.webp')))
          }, null, 8, _hoisted_33),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList3')
          }, null, 8, _hoisted_34),
          _createElementVNode("img", {
            alt: "Ranking App - Loading serveur",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/fetchingLoading.webp'),
            onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/fetchingLoading.webp')))
          }, null, 8, _hoisted_35),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList4')
          }, null, 8, _hoisted_36),
          _createElementVNode("img", {
            alt: "Ranking App - Loading création",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/creationLoading.webp'),
            onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/creationLoading.webp')))
          }, null, 8, _hoisted_37),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList5')
          }, null, 8, _hoisted_38),
          _createElementVNode("img", {
            alt: "Ranking App - Ajout d'un tier",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/newTier.webp'),
            onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/newTier.webp')))
          }, null, 8, _hoisted_39),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList6')
          }, null, 8, _hoisted_40),
          _createElementVNode("img", {
            alt: "Ranking App - No tierlist message",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/noTierlistMessage.webp'),
            onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/noTierlistMessage.webp')))
          }, null, 8, _hoisted_41),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList7')
          }, null, 8, _hoisted_42),
          _createElementVNode("img", {
            alt: "Ranking App - No template message",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/noTemplateMessage.webp'),
            onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/noTemplateMessage.webp')))
          }, null, 8, _hoisted_43),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.UxList8')
          }, null, 8, _hoisted_44),
          _createElementVNode("div", {
            ref: "tests",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.testsTitle')), 513),
          _createElementVNode("div", {
            ref: "unitTests",
            class: "project-section-content-title pt-0",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.unitTestsTitle')
          }, null, 8, _hoisted_45),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.unitTestsContent')
          }, null, 8, _hoisted_46),
          _createElementVNode("img", {
            alt: "Ranking App - Coverage",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/coverage.webp'),
            onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/coverage.webp')))
          }, null, 8, _hoisted_47),
          _createElementVNode("div", {
            ref: "e2eTests",
            class: "project-section-content-title",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.e2eTitle')
          }, null, 8, _hoisted_48),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.e2eContent')
          }, null, 8, _hoisted_49),
          _createElementVNode("img", {
            alt: "Ranking App - Mock",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/mock.webp'),
            onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/mock.webp')))
          }, null, 8, _hoisted_50),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.e2eCypressContent')
          }, null, 8, _hoisted_51),
          _createElementVNode("img", {
            alt: "Ranking App - Cypress",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/cypress.webp'),
            onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/cypress.webp')))
          }, null, 8, _hoisted_52),
          _createElementVNode("div", {
            ref: "githubActions",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.gaTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.gaContent')
          }, null, 8, _hoisted_53),
          _createElementVNode("div", {
            ref: "security",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityContent')
          }, null, 8, _hoisted_54),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList')
          }, null, 8, _hoisted_55),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList2')
          }, null, 8, _hoisted_56),
          _createElementVNode("img", {
            alt: "Ranking App - Access token",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/accessToken.webp'),
            onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/accessToken.webp')))
          }, null, 8, _hoisted_57),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList3')
          }, null, 8, _hoisted_58),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList4')
          }, null, 8, _hoisted_59),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList5')
          }, null, 8, _hoisted_60),
          _createElementVNode("img", {
            alt: "Ranking App - Security headers before",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/securityHeadersBefore.webp'),
            onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/securityHeadersBefore.webp')))
          }, null, 8, _hoisted_61),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.securityList6')
          }, null, 8, _hoisted_62),
          _createElementVNode("img", {
            alt: "Ranking App - Security headers after",
            class: "ranking-app-img",
            src: require('@/assets/images/projects/rankingApp/securityHeadersAfter.webp'),
            onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/rankingApp/securityHeadersAfter.webp')))
          }, null, 8, _hoisted_63),
          _createElementVNode("div", {
            ref: "ssl",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.sslTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.sslContent')
          }, null, 8, _hoisted_64),
          _createElementVNode("div", {
            ref: "conclusion",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content-title pt-4",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionSubtitle1')
          }, null, 8, _hoisted_65),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionContent1')
          }, null, 8, _hoisted_66),
          _createElementVNode("div", {
            class: "project-section-content-title",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionSubtitle2')
          }, null, 8, _hoisted_67),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.rankingApp.details.conclusionContent2')
          }, null, 8, _hoisted_68)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}