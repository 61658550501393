import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aa0d542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-mode" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectMultipleCyberpunk = _resolveComponent("SelectMultipleCyberpunk")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["projects-filtering", { 'projects-filtering-disable': _ctx.disable }])
  }, [
    _createVNode(_component_SelectMultipleCyberpunk, {
      values: _ctx.projectTypes,
      selectedValues: _ctx.projectTypesValues,
      onValueChange: _ctx.onProjectTypeChange
    }, null, 8, ["values", "selectedValues", "onValueChange"]),
    _createVNode(_component_v_spacer),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        variant: "text",
        icon: "mdi-view-grid",
        color: _ctx.viewMode === _ctx.EViewMode.grid ? 'primary' : 'white',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('viewModeChange', _ctx.EViewMode.grid)))
      }, null, 8, ["color"]),
      _createVNode(_component_v_btn, {
        variant: "text",
        icon: "mdi-view-headline",
        color: _ctx.viewMode === _ctx.EViewMode.line ? 'primary' : 'white',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('viewModeChange', _ctx.EViewMode.line)))
      }, null, 8, ["color"])
    ])
  ], 2))
}