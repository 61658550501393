<template>
    <div @click="$emit('click')">
        <a style="cursor: pointer;">
            <p>
                <span class="bg"></span>
                <span class="base"></span>
                <span class="text">{{ text }}</span>
            </p>
        </a>
    </div>
</template>

<script>
export default {
    name: "CustomButton",
    components: {},
    props: {
        text: { type: String },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
$base: #1a1c20;
$white: #fcf1f1;
$pink: rgb(var(--v-theme-primary));
$height: 54px;
$transition: 0.3s ease-out all;

div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    & a {
        width: 100%;
        max-width: 240px;
        padding: 8px;
        font-size: 0.8rem;
        font-weight: 900;
        color: $pink;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        box-shadow: 0 0 0 1px inset rgba($white, 0.3);
        position: relative;
        margin: 10px 0;
        &.white:hover > p {
            color: $white;
        }
        &.white > p {
            background: $white;
            color: $base;
            & span.base {
                border: 1px solid transparent;
            }
        }
        &.transparent:hover > p {
            color: $white;
            & span.text {
                box-shadow: 0 0 0 1px $white;
            }
        }
        &.transparent > p {
            background: $base;
            color: $white;
            & span.base {
                border: 1px solid $white;
            }
        }
        &:after,
        &:before {
            content: "";
            width: 1px;
            position: absolute;
            height: 8px;
            background: $base;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            right: 0;
            left: initial;
        }
        & p {
            margin: 0;
            height: $height;
            line-height: $height;
            box-sizing: border-box;
            z-index: 1;
            left: 0;
            width: 100%;
            position: relative;
            overflow: hidden;
            & span.base {
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                left: 0;
                border: 1px solid $pink;
                &:before {
                    content: "";
                    width: 2px;
                    height: 2px;
                    left: -1px;
                    top: -1px;
                    background: $base;
                    position: absolute;
                    transition: $transition;
                }
            }
            & span.bg {
                left: -5%;
                position: absolute;
                background: $pink;
                width: 0;
                height: 100%;
                z-index: 3;
                transition: $transition;
                transform: skewX(-10deg);
            }
            & span.text {
                z-index: 4;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                &:after {
                    content: "";
                    width: 4px;
                    height: 4px;
                    right: 0;
                    bottom: 0;
                    background: $base;
                    position: absolute;
                    transition: $transition;
                    z-index: 5;
                }
            }
        }
        &:hover {
            color: $white;
            & span.bg {
                width: 110%;
            }
            & span.text:after {
                background: $white;
            }
        }
    }
}
</style>
