import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a2a0da7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-title" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "header-container d-flex align-center justify-center mt-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "summary" }
const _hoisted_6 = { class: "project-section-title" }
const _hoisted_7 = { class: "ml-6" }
const _hoisted_8 = { class: "summary-title" }
const _hoisted_9 = { class: "summary-title" }
const _hoisted_10 = { class: "summary-title" }
const _hoisted_11 = { class: "summary-title" }
const _hoisted_12 = { class: "summary-title" }
const _hoisted_13 = { class: "summary-title" }
const _hoisted_14 = { class: "summary-title" }
const _hoisted_15 = { class: "summary-title" }
const _hoisted_16 = { class: "summary-title" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["src"]
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["src"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["src"]
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["src"]
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = ["src"]
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = ["src"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["innerHTML"]
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = ["src"]
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageExtend = _resolveComponent("ImageExtend")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showDialog) = $event)),
    fullscreen: "",
    "hide-overlay": "",
    transition: "dialog-bottom-transition"
  }, {
    default: _withCtx(() => [
      (_ctx.imageToExtend)
        ? (_openBlock(), _createBlock(_component_ImageExtend, {
            key: 0,
            imgId: _ctx.imageToExtend,
            imgur: false,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageToExtend = null))
          }, null, 8, ["imgId"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_card, { class: "project-container kaisen-workout-project-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_CloseButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              alt: "Kaisen Workout logo",
              src: require('@/assets/images/projects/kaisenWorkout/logo.png')
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              alt: "Kaisen Workout - Overview",
              src: require('@/assets/images/projects/kaisenWorkout/demo.gif'),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/demo.gif'))),
              style: {"margin-right":"15px"}
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.summary')), 1),
              _createElementVNode("ul", _hoisted_7, [
                _createElementVNode("li", _hoisted_8, [
                  _createElementVNode("a", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollToPart('inShort')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.inShortTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_9, [
                  _createElementVNode("a", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scrollToPart('what')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.whatTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_10, [
                  _createElementVNode("a", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.scrollToPart('architecture')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.architectureTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_11, [
                  _createElementVNode("a", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.scrollToPart('program')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.programTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_12, [
                  _createElementVNode("a", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.scrollToPart('workout')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.workoutTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_13, [
                  _createElementVNode("a", {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.scrollToPart('progression')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.progressionTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_14, [
                  _createElementVNode("a", {
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.scrollToPart('preferences')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.preferencesTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_15, [
                  _createElementVNode("a", {
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.scrollToPart('copilot')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.copilotTitle')), 1)
                ]),
                _createElementVNode("li", _hoisted_16, [
                  _createElementVNode("a", {
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.scrollToPart('conclusion')))
                  }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.conclusionTitle')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            ref: "inShort",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.inShortTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.inShortList')
          }, null, 8, _hoisted_17),
          _createElementVNode("div", {
            class: "project-section-content mt-6",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.inShortContent')
          }, null, 8, _hoisted_18),
          _createElementVNode("div", {
            ref: "what",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.whatTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.whatContent')
          }, null, 8, _hoisted_19),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.whatList')
          }, null, 8, _hoisted_20),
          _createElementVNode("div", {
            ref: "architecture",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.architectureTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.architectureContent')
          }, null, 8, _hoisted_21),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.architectureContent2')
          }, null, 8, _hoisted_22),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Architecture",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/architecture.png'),
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/architecture.png')))
          }, null, 8, _hoisted_23),
          _createElementVNode("div", {
            ref: "program",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.programTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.programContent')
          }, null, 8, _hoisted_24),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Program",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/program.webp'),
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/program.webp')))
          }, null, 8, _hoisted_25),
          _createElementVNode("div", {
            ref: "workout",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.workoutTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.workoutContent')
          }, null, 8, _hoisted_26),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Last session code",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/lastSessionCode.png'),
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/lastSessionCode.png')))
          }, null, 8, _hoisted_27),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.workoutContent2')
          }, null, 8, _hoisted_28),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Last rep code",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/lastRepCode.png'),
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/lastRepCode.png')))
          }, null, 8, _hoisted_29),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.workoutContent3')
          }, null, 8, _hoisted_30),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Workout",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/workout.webp'),
            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/workout.webp')))
          }, null, 8, _hoisted_31),
          _createElementVNode("div", {
            ref: "progression",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.progressionTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.progressionContent')
          }, null, 8, _hoisted_32),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Progression",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/progression.webp'),
            onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/progression.webp')))
          }, null, 8, _hoisted_33),
          _createElementVNode("div", {
            ref: "preferences",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.preferencesTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.preferencesContent')
          }, null, 8, _hoisted_34),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.preferencesList')
          }, null, 8, _hoisted_35),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.preferencesContent2')
          }, null, 8, _hoisted_36),
          _createElementVNode("img", {
            alt: "Kaisen Workout - Preferences",
            class: "kaisen-workout-img",
            src: require('@/assets/images/projects/kaisenWorkout/preferences.webp'),
            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.imageToExtend = require('@/assets/images/projects/kaisenWorkout/preferences.webp')))
          }, null, 8, _hoisted_37),
          _createElementVNode("div", {
            ref: "copilot",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.copilotTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.copilotContent')
          }, null, 8, _hoisted_38),
          _createElementVNode("div", {
            ref: "conclusion",
            class: "project-section-title"
          }, _toDisplayString(_ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.conclusionTitle')), 513),
          _createElementVNode("div", {
            class: "project-section-content",
            innerHTML: _ctx.$vuetify.locale.t('$vuetify.projects.kaisenWorkout.details.conclusionContent')
          }, null, 8, _hoisted_39)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}