import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-717c04cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "btn__glitch" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.values, (value, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: value,
        class: _normalizeClass(["radio-wrapper", { 'radio-wrapper-active': $props.selectedValues.includes(value) }]),
        onClick: ($event: any) => (_ctx.$emit('valueChange', value))
      }, [
        _createElementVNode("div", {
          id: 'value-' + index,
          name: "btn",
          class: "input"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", {
          class: _normalizeClass(["btn", { 'active': $props.selectedValues.includes(value) }])
        }, [
          ((index === 0 || index === 1) && _ctx.windowWidth > _ctx.SIZE_BREAKPOINT)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " _ "))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.$vuetify.locale.t(value)) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString($options.getGlitchedValue(_ctx.$vuetify.locale.t(value))), 1),
          ((index === 1 || index === 2) && _ctx.windowWidth > _ctx.SIZE_BREAKPOINT)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " _ "))
            : _createCommentVNode("", true),
          _createElementVNode("label", _hoisted_7, _toDisplayString('r' + index), 1)
        ], 2)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}